import React from 'react';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import HeaderStyles from '../styles/HeaderStyles';

export default function NotFound() {
  return (
    <Layout>
      <SEO title="Not Found" />
      <HeaderStyles>404</HeaderStyles>
    </Layout>
  );
}
