import styled from 'styled-components';

const HeaderStyles = styled.h1`
  color: #131313;
  position: fixed;
  font-weight: 400;
  top: -5px;
  right: -20px;
  font-size: 70rem;
  text-align: right;
  transition: font-size 1s;
  @media (max-width: 768px) {
    top: 120px;
    text-align: left;
    right: -500px;
  }
  @media (max-width: 480px) {
    text-align: left;
    top: 120px;
    right: -875px;
  }
`;

export default HeaderStyles;
